body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* SEND OTP screen start*/
.send-otp_container {
  max-width: 800px;
  height: auto;
  padding: 20px;
  /* / border: 1px solid; / */
  margin: auto;
}

.lable-otp,
.otp-numb {
  font-size: 20px;
  font-weight: 700;
  line-height: 26.5px;
  text-align: center;
}

.lable-otp {
  color: rgba(29, 36, 44, 1);
}

.otp-numb {
  color: rgba(38, 173, 255, 1);
}

.otp-numbers-box {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 20px 0;
}

.otp-input {
  width: 70px;
  height: 70px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(153, 168, 180, 0.49);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  text-align: center;
}

/* / Chrome, Safari, Edge, Opera / */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}

.resend-otp {
  color: rgba(73, 101, 123, 1);
  font-weight: 500;
  margin: 100px 0 20px 0;
}

.resend-otp,
.resend-otp a {
  font-size: 15px;
  line-height: 18.15px;
  text-align: center;
}

.resend-otp a {
  font-weight: 600;
  /* / background: linear-gradient(90deg, #1379C4 0%, #1DA1F2 100%); / */
  background: linear-gradient(90deg, #1379c4, #1da1f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.send-otp-popup .modal-content {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  height: 423px;
  border: 1px solid rgba(153, 168, 180, 1);
  box-shadow: 0px -8px 0px 0px rgba(153, 168, 180, 1) inset;
  background-color: rgba(255, 255, 255, 1);
  padding: 0 22px;
}

.send-otp-popup .modal-dialog {
  max-width: 480px;
  position: relative;
}

.send-otp-popup .btn-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.wrapper {
  width: 250px;
  height: 250px;
  position: relative;
  background: rgb(255, 255, 255);
}

.pie {
  width: 50%;
  height: 100%;
  position: absolute;
  background: #08C;
  border: 10px solid rgba(0, 0, 0, 0.4);
}

.spinner {
  border-radius: 125px 0 0 125px;
  z-index: 200;
  border-right: none;
  animation: rota 10s linear infinite;
}

.filler {
  border-radius: 0 125px 125px 0;
  z-index: 100;
  border-left: none;
  animation: fill 10s steps(1, end) infinite;
  left: 50%;
  opacity: 0;
}

.mask {
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 300;
  opacity: 1;
  background: inherit;
  animation: mask 10s steps(1, end) infinite;
}

.dob-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.dob-input-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dob-input-field {

  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}



@keyframes rota {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fill {
  0% {
    opacity: 0;
  }

  50%,
  100% {
    opacity: 1;
  }
}

@keyframes mask {
  0% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}

@media (max-width: 575px) {
  .send-otp_container {
    padding: 0;
  }

  .otp-numbers-box {
    gap: 5px;
    margin: 20px 0;
  }

  .lable-otp,
  .otp-numb {
    font-size: 16px;
    line-height: 21.5px;
  }

  .otp-input {
    width: 100%;
    height: auto;
    padding: 15px;
  }

  .resend-otp,
  .resend-otp a {
    font-size: 13px;
    line-height: 13.15px;
  }
}

/* SEND OTP screen start*/

/* For input box in drag and drop question */
.drop-box {
  display: inline-block;
  width: 150px;
  height: 40px;
  margin: 5px 10px;
  padding: 10px;
  border: 2px solid #F6F8F9;
  border-radius: 5px;
  background-color: #F6F8F9;
  vertical-align: middle;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
}

.draggable-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background: rgba(246, 248, 249, 1);
  border: 1px solid rgba(201, 210, 217, 1);
  box-shadow: 0px -5px 0px 0px rgba(73, 101, 123, 0.26) inset;
  padding-bottom: 10px;
  border-radius: 18px;
  padding: 15px 18px 17px 22px;
  cursor: pointer;
}

.draggable-item:active {
  background-color: #e0e0e0;
}

.draggable-items {
  display: grid;
  gap: 30px;
  margin: 20px 0;
  grid-template-columns: repeat(3, 1fr);
}

.correct {
  background-color: rgba(83, 223, 131, 1);
}

.wrong {
  background-color: rgba(254, 70, 70, 1);
}

.draggable-item[draggable="false"] {
  pointer-events: none;
  /* Disable all mouse interactions */
}

.questions {
  height: auto !important;
}

.questions-box {
  height: auto !important;
}

.red-color {
  background: green;
}

/* .tab-container1 {
  padding: 4px 3px 7px 3px !important;
} */

.box-slide-01.category-id {
  background-color: inherit;
}

strong {
  font-weight: bolder !important;
}

.react-select-container {
  width: 8em;
  margin-top: -50px;
  margin-left: 10px;
}

.common_form .mobile-number .form-control {
  padding-left: 10em !important;
}

.mobile-number>label {
  left: 125px !important;
}

.react-select__control {
  border: none !important;
}

.react-select__control.react-select__control--is-focused.react-select__control--menu-is-open {
  border: none !important;
  box-shadow: none !important;
}

.react-select__menu.css-1nmdiq5-menu {
  width: 15em !important;
  z-index: 99999 !important;
}

.react-select__control.react-select__control--is-focused {
  box-shadow: none !important;
}

.mobile-error {
  margin-top: 12px;
}

.custom-dropdown {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  min-width: 200px;
}

.custom-dropdown option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-container {
  position: relative;
}

.form-select {
  font-family: 'Arial', sans-serif;
  color: #333;
}

/* Add additional custom styling for hover and focus if needed */

.cursor-pointer {
  cursor: pointer;
}

.word-break-all {
  word-break: break-all;
}

.header-category-dropdown .chat-body img {
  width: 35px !important;
  height: 35px !important;
}

.header-category-dropdown .dropdown-menu {
  left: 0;
  width: 20em !important;
}

.line-badge-horizontal {
  position: absolute;
  top: 50%;
  left: 89%;
  transform: translateY(-50%);
}

.small-footer-link ul li button {
  font-family: "ProximaSoft";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 14px;
  color: #1D242C;
  background: transparent;
  border: none;
}

.choose-your-plan .login-title.text-center.mb-5 p {
  color: #49657B;
}

.features-main p {
  color: #49657B;
}

#billing-info .modal-content {
  background: transparent;
  border: none;
}

.bronze-silver_badge-box-down.bronze-silver_badges {
  margin-top: 30px;
}

.bronze-silver_badges.odd {
  justify-content: start;
  margin-top: 30px;
}

/* .bronze-silver_badge-box.position-relative.inactive {
  pointer-events: none;
} */

.bronze-silver_badge-box.position-relative.inactive img.badge_image2 {
  filter: grayscale(1);
}

a.btn-profile:hover {
  color: #1d242c;
}

.course_box input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
}

.course_box .left {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.mobile-number .react-select__single-value {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  font-family: "ProximaSoft";
  color: #1d242c;
}

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.download-button-wrapper {
  padding: 0 25px;
}

.download-button {
  background-color: #26ADFF;
  color: #fff;
  padding: 13px 25px;
  border-radius: 14px;
  border: 1px solid #189BEA;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.45) inset;
}

.download-icon {
  height: 23px;
  margin-right: 8px;
}

.right_content_head_box {
  width: 100%;
}

.lession_box .info_video .lesson_description {
  font-family: "Inter", sans-serif;
  text-align: start;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: rgba(73, 101, 123, 1);
}

.lession_box .info_video .lesson_title {
  font-family: "ProximaSoft", sans-serif;
  line-height: 19.25px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(29, 36, 44, 1);
}

.box_design-01.active .numbs {
  color: white !important;
}


.box_design-01.active .round-photo_box {
  background-color: rgba(233, 151, 77, 1);
}

.weekly_leaderboard .box_left {
  pointer-events: none;
}

.faq-main .no-data {
  background-color: transparent !important;
  box-shadow: none;
  font-family: "ProximaSoft";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  color: #1d242c;
  padding: 20px;
}

.content-view-lessions .video-container {
  width: 100%;
  height: 300px;
}

.content-view-lessions .video-container video {
  width: 100%;
  height: 300px;
}


.theme-small-modal img {
  height: 300px !important;
  width: 253px !important;
}

.title_box-downloaded-lessions span .arrow{
  background-image: url("../public/assets/img/icon-arrow-down.svg");
}

.bronze-silver_badge-box_empty {
  width: 140px;
  height: 136px;
  padding: 17px;
  text-align: center;
}

.my-profile .css-qr46ko{
  max-height: 180px;
}

.subscription-list.form-check.inactive {
  /* background: #eef6ff; */
  border: 1px solid #99A8B4;
  box-shadow: inset 0px -4px 0px #99A8B4;
  border-radius: 15px;
  padding: 0;
  position: relative;
}

.subscription-list.form-check.active {
  background: #eef6ff;
  border: 1px solid #1c7bb5;
  box-shadow: inset 0px -4px 0px #1c7bb5;
  border-radius: 15px;
  padding: 0;
  position: relative;
}

.referral-code-container{
  /* border: 1px solid #1c7bb5; */
  background-image: url("../public/assets/img/rectangle-copypath.png");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: 0;
  height: 5em;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 25px 10px 15px;
}

.copy-clip-btn {
  background-image: url('../public/assets/img/copy-clip.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 0;
}

.copy-text{
  color: rgba(38, 173, 255, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
}

.Spinner_loader__GQ3jW {
  left: 50% !important;
}